// project specific CSS goes here

////////////////////////////////
//Variables//
////////////////////////////////

// Root variables
$font-family-sans-serif: 'Sentinel A', 'Sentinel B';
$font-size-base: 0.9rem;

// Theme colors
$theme-colors: (
  'danger': #ffe0e0,
  'warning': #cccccc,
  'success': #e6f9c6,
  'info': #c6e6f9,
);

// Alert colors
$white: #fff;
$mint-green: #d6e9c6;
$black: #000;
$pink: #f2dede;
$dark-pink: #eed3d7;
$red: #b94a48;

//@import "node_modules/bootstrap/scss/bootstrap";

////////////////////////////////
//Alerts//
////////////////////////////////

// bootstrap alert CSS, translated to the django-standard levels of
// debug, info, success, warning, error

.alert-debug {
  background-color: $white;
  border-color: $mint-green;
  color: $black;
}

.alert-error {
  background-color: $pink;
  border-color: $dark-pink;
  color: $red;
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: center;
  vertical-align: middle;
}

thead th {
  background-repeat: no-repeat;
  background-position: right center;
}

thead th.up {
  padding-right: 20px;
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
}

thead th.down {
  padding-right: 20px;
  background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
}

.checked {
  background-color: #fefbcd;
}

.text-winteam {
  //color: #008000;
  color: #008000;
}

.text-loseteam {
  color: #ff0000;
}

.text-inprogress {
  color: #3399ff;
}

#bannertype {
  font-size: 1.6rem;
  font-family: 'Obsidian A', 'Obsidian B';
  font-style: normal;
  font-weight: 400;
}

@import '../../../node_modules/bootstrap/scss/bootstrap';
